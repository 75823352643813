.App {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow-x: initial;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

.popup-content {
  margin: auto;
  background: #e8e8e8;
  width: 50%;
  padding: 5px;
}
.modalContainer {
  padding: 20px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.sendButton {
  margin: 10px;
  border: none;
  height: 50px;
  width: 100px;
  border-radius: 50px;
  color: white;
  background-color: blue;
}
.sendButton :hover {
  background-color: darkblue;
}
.btn-modalClose {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.modalContainer label {
  margin-top: 10px;
  color: gray;
}
.modalContainer input {
  margin-top: 15px;
  border: 1px solid gray;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}
.buttonModal {
  position: fixed;
  top: 100px;
  left: 100px;
  border-radius: 50px;
  height: 100px;
  width: 100px;
  border: none;
}
