.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}
.content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.content {
  text-align: center;
}
.custom-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.custom-bg img {
  width: 100%;
  height: 80vh;
}
.title {
  justify-self: flex-start;
  width: 100%;
  height: 100%;
  align-items: center;
  margin-top: 150px;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  width: 100%;
  bottom: 50px;
  gap: 20px;
  /* margin-top: 20px; */
}
.arrowDownDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
  bottom: 40px;
}
.arrowDownIcon{
  animation: animateDown infinite 1.5s;
  height: 30px;
  width: 30px;
}
.blackButton {
  background-color: #3b3d42;
  color: white;
  text-decoration: none;
  text-align: center;
  border: none;
  border-radius: 50px;
  height: 40px;
  font-size: 12px;
  font-weight: bold;
  width: 260px;
  /* margin-left: 20px;
  margin-bottom: 10px;  */
}
.whiteButton {
  background-color: white;
  text-align: center;
  color: gray;
  text-decoration: none;
  border: none;
  border-radius: 50px;
  height: 40px;
  font-size: 12px;
  font-weight: bold;
  width: 260px;
  /* margin-left: 20px;
   margin-bottom: 10px; */
  box-shadow: inset 0 0 0 1px #e1e1e1, 0 0 1px rgba(0, 0, 0, 0);
}

@keyframes animateDown{
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  } 
  40%{
    transform: translateY(5px);
  }
  60%{
    transform: translateY(3px)
  }
}

@media screen and (max-width: 994px) {
  .buttons{
    flex-direction: column;
    margin-bottom: 0px;
    justify-content: flex-end;
    align-items: center;
    bottom: 50px;
    gap: 10px;
    
  }
  .custom-bg img {
    width: 400px;
    height: 300px;
  }
}
/* 
IPHONE 5 */
@media (min-width: 320px) and (max-height: 568px)  {
  .custom-bg img {
    width: 350px;
    height: 300px;
  }
}
/* IPAD */
@media (min-width: 768px) and (max-height: 1024px)  {
  .custom-bg img {
    width: 611px;
    height: 408px;
  }
}
/* IPAD PRO*/
@media (min-width: 1024px) and (max-height: 1366px)  {
  .custom-bg img {
    width: 1000px;
    height: 100%
  }
}