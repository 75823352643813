.nav-menu {
  position: relative;
  background-color: black;
  width: 250px;
  height: 100vh;
  display: flex;
  /* justify-content: center;
    align-items: center; */
  text-align: center;
  position: fixed;
  top: 0;
  right: -100%;
  font-weight: bold;
  transition: 850ms;
  z-index: 4;

}
.navbar-menu {
  box-shadow: 0 0 1em gray;
  position: relative;
  background-color: white;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
    align-items: center;
  text-align: center;
  position: fixed;
  top: 0;
  right: -100%;
  font-weight: bold;
  transition: 850ms;
  z-index: 3;

}
.navbar-menu ul li{
  color: black;
  margin-bottom: 20px;
  list-style: none;
  height: 20px;
  width: 200px;
  text-align: center;

}
.navbar-menu ul li:hover{
  color: black;
  width: auto;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  /* transition: background-color 1000ms left; */
  transition-timing-function: ease-out;
  /* background-position: 0 -100%; */
  cursor: pointer;
  background-position: 0 -100%;
  height: 20px;

}
.navbar-menu.active{
  right: 0;
  transition: 350ms;
  z-index:3;
}
.nav-menu ul li {
  color: white;
  margin-bottom: 20px;
  list-style: none;
  height: 20px;
  width: 100px;
  text-align: center;
}
.nav-menu ul li:hover {
  color: black;
  width: 100px;
  text-align: center;
  background-color: white;
  border-radius: 14px;
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  /* transition: background-color 1000ms left; */
  transition-timing-function: ease-out;
  /* background-position: 0 -100%; */
  cursor: pointer;
}
.nav-menu ul li.active {
  color: black;
  font-weight: bold;
}

.nav-menu.active {
  right: 0;
  transition: 350ms;
}
.menu-close {
  align-self: flex-start;
  justify-content: flex-start;
  justify-self: flex-end;
  width: fit-content;
  height: fit-content;
}
.linkStyle {
  color: white;
}

.linkStyle.active {
  color: white;
}

.dropSideModels {
  display: flex;
  justify-content: center;
}
.fechaMenu.active{
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  inline-size: 120vw;
  block-size: 120vh;
  display: block;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(4px);
  z-index: 1;
  position: fixed;
}
.fechaMenu{
  display: none;
}
.buttonMenuSidebar{
  color: white;
}
.buttonMenuSidebar:hover{
  color:black;
  height: 20px;
  cursor:pointer;
  border-radius: 14px;
  background-color: white;
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition-timing-function: ease-out;
  background-position: 0 -100%;
  border-radius: 14px;
}
.dropSideModels:hover {
  color: black;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  /* transition: background-color 1000ms left; */
  transition-timing-function: ease-out;
  background-position: 0 -100%;
}
.dropSideModels:hover .dropSideContent {
  display: block;
}
.dropSideModels:active .dropSideContent {
  display: block;
}
.dropSideContent {
  padding: 15px;
  left: -100px;
  display: none;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  width: fit-content;
  height: fit-content;
  font-size: 12px;
  position: absolute;
  z-index: 2;
  float: left;
  background-color: black;
  color: white;
}
.navLogo{
  width:200px;
  cursor: pointer;
}

.menuDropDown {
  color: black;
  display: flex;
  justify-content: center;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-decoration: none;
  /* cursor: pointer; */
  font-size: 14px;
  position: relative;
  height: fit-content;
  padding: 5px;
  z-index: 2;
 
}

/* ESTILOS DO NAV */
.dropDownContent {
  display: none;
  text-align: center;
  position: absolute;
  top: 20px;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  width: fit-content;
  height: 100vh;
  z-index: 1;
  float: left;
  margin-right: 20%;
  /* margin-right: 50px;
  -webkit-margin-end:50px; */
}
.dropDownContent.active {
  right: 0;
  transition: 350ms;
}
.dropDownContent ul li {
  width: 150px;
  font-size: 12px;
  margin: 10px;
  list-style-type: none;
  text-align: center;
}
.dropDownContent ul li:hover {
  color: black;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  /* transition: background-color 1000ms left; */
  transition-timing-function: ease-out;
  background-position: 0 -100%;
}

.menuDropDown:hover {
  color: black;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  /* transition: background-color 1000ms left; */
  transition-timing-function: ease-out;
  background-position: 0 -100%;
}
/* .menuDropDown:hover .dropDownContent {
  display: block;
} */
@media screen and (max-width: 1024px){
  .navLogo {
    width: 100px;
    cursor: pointer;
  }
}