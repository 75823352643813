.slider {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}
.slider:hover .arrows {
  opacity: 100;
}

.image {
  width: auto;
  height: 300px;
  border-radius: 10px;
}
.imageContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* height: 500px; */
}
.arrows {
  opacity: 0;
  top: 0;
  left: 0;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
}
.right-arrow {
  background-color: #d3d3d3;
  border-radius: 50px;
  color: black;
  position: absolute;
  top: 50%;
  right: 32px;
  height: 32px;
  width: 32px;
  font-size: 10px;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  /* border-radius: 50px; */
  background-color: #d3d3d3;
  border-radius: 50px;
  color: black;
  position: absolute;
  top: 50%;
  left: 32px;
  height: 32px;
  width: 32px;
  font-size: 10px;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}
@media screen and (max-width: 992px) {
  .image {
    width: 80vw;
    height: 500px;
  }
  .slider {
    height: 500px;
    justify-content: center;
    align-items: center;
  }
}
