.rec.rec-arrow {
    width: 28px;
    height: 28px;
    color: white;
    background-color: black;
    border: 1px solid white;
    border-radius: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rec.rec-arrow:hover  {
    background-color: white !important;
    color: black !important;
}

.rec.rec-arrow:focus {
    background-color: white !important;
    color: black !important;
}
.rec.rec-arrow:active {
    background-color: white !important;
    color: black !important;
}

/* hide disabled buttons */
.rec.rec-arrow:disabled {
    visibility: hidden;
}
.rec.rec-carousel-wrapper .rec-pagination .rec.rec-dot.rec.rec-dot_active {
    background: white;
    
  }
  .rec.rec-carousel-wrapper .rec-pagination .rec.rec-dot.rec {
    box-shadow: none;
    background: rgba(37, 34, 34, 0.452);
  }
  .rec-carousel-item {
      width:"100vw"
  }
  .rec-slider-container{
    width:"100vw"
  }

  @media screen and (max-width: 900px) {
    .rec.rec-arrow {
        width: 4px;
        height: 28px;

    }
  }