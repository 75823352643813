.aboutContainer{
     height:100%;
    width: 100%;
    display: flex;

    align-items: flex-start;
    box-sizing: border-box;
}
.contentContainer{
    display: flex;
    flex-direction: column;
    height:100%;
    width: 100%;
    justify-content: flex-start;
    margin: 70px;
}
.headerContainer{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #F4F4F4;
}
.headerContainer ul li{
    list-style: none;
    display: inline-block;
    margin: 10px;
    color:gray;
}
.headerContainer h1{
    font-size: 30px;
}
.innerContent{
    padding-top: 50px;
}
.innerContent p{
    font-size: 14px;
    color: #64666A;
    line-height: 21px;
}
.innerContent ul li{
    list-style: none;
}
.innerContent ul li:before {
    content: '✓';
  }
  /* PAGINA DE CONTATO */
  .contactForm{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      /* width: 100%; */
      align-items: center;
      margin-top: 50px; 
      gap: 50px;

      box-shadow: 0 0 1em #E8E8E8;
      border-radius: 50px;
      padding: 50px;
  }
  .contactForm input{
      background-color: #F4F4F4;
      padding: 10px;
      border-radius: 50px;
      border: none;

  }
  .contactForm textarea{
    background-color: #F4F4F4;
    padding: 10px;
    border-radius: 50px;
    border: none;
    text-align: center;

}

  .contactFormGroup{
    width: 400px;
    height: fit-content;
  }
  /* .innerContentContact{
    width: 100%;
    height:  100vh;
    padding-top: 50px;
  } */
  .submitButton{
      border-radius: 50px;
      width: 200px;
      justify-self: center;
      align-self: center;
  }
  @media screen and (max-width: 994px){
    .contactFormGroup{
        width: auto;
        height: auto;
    }

      .contactForm{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      align-items: center;
      margin-top: 50px; 
      /* gap: 50px; */

      box-shadow: 0 0 1em #E8E8E8;
      border-radius: 50px;
      /* padding: 50px; */
  }
  .contentContainer{
      margin: 10px;
  }
  .contentContainer{
      margin: 25px;
  }
  #firstLi {
    display: none;
  }
 
  }
  @media screen and (max-width: 1024px) and (min-height: 768px){
    .contactForm{
        flex-direction: column;
    }
  }