body {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.columnSection {
  display: flex;
  flex-wrap: wrap;
}

.hidden {
  display: none;
}
.show {
  -webkit-animation: slide-in 1s forwards;
  animation: slide-in 1s forwards;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
}
.leftDiv {
  position: fixed;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 50vw;
}
.leftContainer {
  height: 100vh;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.rightDiv {
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-left: 4em;
  padding-right: 4em;
  margin-top: 15em;
  width: 25%;
  justify-content: flex-end;
  align-items: center;
}
.rightDiv p {
  color: gray;
}
.description {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 20px;
  height: 500px;
}
.imgVisaoCasco {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
}
.imgVisaoCascoCores {
  /* height: 100%;
  width: 100%; */
  position: relative;
  z-index: 1;
}
.imgCascoDefault {
  /* background-color:pink; */
  position: absolute;
  z-index: 0;
}
.imgCasco {
  position: absolute;
  z-index: 1;
}
.imgCascoCor {
  position: absolute;
  z-index: 2;
}
.containerVisao {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  top: 100%;
  justify-content: center;
  align-items: center;
}
.containerPiso {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.leftContainer:hover .arrowsCustomPage {
  opacity: 100;
}
.arrowsCustomPage {
  width: 65%;
  height: auto;
  z-index: 10;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  top: 50%;
  position: fixed;
  left: 0;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
}
.right-arrowCustomPage {
  background-color: #d3d3d3;
  border-radius: 50px;
  color: black;
  position: absolute;
  top: 50%;
  right: 32px;
  height: 32px;
  width: 32px;
  font-size: 10px;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrowCustomPage {
  /* border-radius: 50px; */
  background-color: #d3d3d3;
  border-radius: 50px;
  color: black;
  position: absolute;
  /* top: 50%; */
  left: 32px;
  top: 50%;
  height: 32px;
  width: 32px;
  font-size: 10px;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}
.loadIcon {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  display: inline-block;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.cascoColor {
  z-index: 4;
  position: relative;
  height: fit-content;
  width: 100%;
  display: block;
}
.ButtonsMotor {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.Buttons {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: background;
  transition-property: background;
  box-shadow: inset 0 0 0 2px #e1e1e1, 0 0 1px rgba(0, 0, 0, 0);
  padding: 8px 76px;
  text-decoration: none;
  border: none;
  font-size: 14px;
  border-radius: 28px;
}
.Buttons:hover,
.Buttons:focus,
.Buttons:active {
  background: none;
}
.info {
  display: flex;
  justify-content: center;
  padding: 20px;
}
.text {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 10px;
  font-weight: bold;
  font-size: 20px;
}
.text p {
  font-weight: normal;
  font-size: 14px;
}

.motorButton {
  margin-top: 10px;
  background-color: transparent;
  border-radius: 28px;
  border: 1px solid gray;
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  width: 400px;
  padding: 14px 76px;
  text-decoration: none;
}
.motorButton:hover {
  background-color: transparent;
  top: 1px;
}
.motorButton:focus {
  border: 2px solid blue;
  background-color: transparent;
}
.motorButton:active {
  position: relative;
  border: 1px solid blue;
  top: 1px;
}
.buttonColors {
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
  padding: 20px;
  z-index: 5;
}
.mobileWoodLabel {
  font-size: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 5;
}

@media (max-height: 950px) {
  .imgCascoDefault {
    position: absolute;
    z-index: 0;
    height: 70%;

    /* background-color: aqua; */
    /* background-color: pink; */
  }
  .imgCasco {
    position: absolute;
    z-index: 1;
    height: 70%;

    /* background-color: aqua; */
  }
  .imgCascoCor {
    position: absolute;
    z-index: 2;
    height: 70%;

    /* background-color: aqua;  */
  }
  .containerVisao {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (width: 1280px) and (height: 950px) {
  .imgCascoDefault {
    position: absolute;
    z-index: 0;
    height: 50%;
    width: auto;
  }
  .imgCasco {
    position: absolute;
    z-index: 1;
    height: 50%;
  }
  .imgCascoCor {
    position: absolute;
    z-index: 2;
    height: 50%;
  }
  .containerVisao {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-device-width: 810px) and (max-device-width: 1080px) and (orientation: landscape) {
  .imgCascoDefault {
    position: absolute;
    z-index: 0;
    height: 50%;
    width: auto;
  }
  .imgCasco {
    position: absolute;
    z-index: 1;
    height: 50%;
  }
  .imgCascoCor {
    position: absolute;
    z-index: 2;
    height: 50%;
  }
}
@media screen and (max-width: 1024px) {
  body {
    overflow-x: hidden;
  }

  .mobileWoodLabel {
    font-size: 15px;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .buttonColors {
    display: flex;
    width: fit-content;
    justify-content: center;
    /* flex-wrap: wrap; */
    padding: 50px;
    flex-wrap: wrap;
  }
  .arrowsCustomPage {
    position: absolute;
    justify-content: space-between;
    width: 100%;
  }

  .containerVisao {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    top: 10px;
    justify-content: center;
    align-items: center;
  }
  .columnSection {
    display: flex;
    flex-direction: column;
  }
  .leftContainer {
    width: 100%;
    height: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-self: center;
    display: flex;
  }
  .leftDiv {
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60vh;
    top: 15px;
  }
  .rightDiv {
    /* background-color: pink; */
    width: 100%;
    margin-top: 0px;
    top: 0px;
  }
  .imgCascoDefault {
    /* max-width: 100%; */
    width: 100%;
    height: auto;
  }
  .imgCasco {
    width: 100%;
    height: auto;
  }
  .imgCascoCor {
    width: 100%;
    height: auto;
  }

  .motorizacaoLab {
    margin-left: 20px;
  }
  .buttonsMotor {
    padding: 20px;
  }
  .motorButton {
    width: 300px;
    margin: 100px;
  }
}
.buyButton {
  background-color: blue;
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 50px;
  height: 40px;
  font-size: 12px;
  font-weight: bold;
  width: 260px;
  margin-left: 20px;
  margin-bottom: 10px;
}
.buyButton:hover {
  background-color: darkblue;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: background;
  transition-property: background;
}

.acessorios {
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}

/* estilo do "lançamento" do navbar */
.lancamento p {
  margin-left: 8px;
  font-size: 8px;
  color: red;
}
.lancamento {
  height: fit-content;
  width: fit-content;
  justify-content: center;
  align-items: center;
}
.list li {
  height: 2em;
}
.list-style {
  color: gray;
  font-weight: light;
}
.list-style li {
  padding-bottom: 1em;
}
@media (max-width: 767px) {
  .list li {
    height: 4em;
  }
}
